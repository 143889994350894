import { sleep } from "@/services/utils";
import { getLog } from "./log";
let log = getLog('mediautils');

export function stopStream(stream) {
  if (stream)
    stream.getTracks().forEach(track => track.stop());
}

export function getStreamInfo(stream) {
  let res = [];
  if (!stream)
    return res;
  stream.getTracks().forEach(t => {
    res.push(t.getSettings());
    res.push(t.getCapabilities());
  });
  return res.length > 1 ? res : res[0];
}

export function getStreamSettings(stream) {
  if (stream.getVideoTracks()[0])
    return stream.getVideoTracks()[0].getSettings();
  else if (stream.getAudioTracks()[0])
    return {audioOnly: true};
  else
    return {empty:true};
}

export async function getStreamDimensions(stream) {
  let loop = true;
  while (loop) {
    let settings = getStreamSettings(stream);
    if (!settings.deviceId || (settings.width && settings.height))
      return settings;
    await sleep(100);
  }
}

// gUM with highest/targeted quality.
export let deviceDefinitions = [
  {
      "label": "4K",
      "width": 3840,
      "height": 2160,
      "ratio": "16:9"
  },
  {
      "label": "1080p",
      "width": 1920,
      "height": 1080,
      "ratio": "16:9"
  },
  {
      "label": "UXGA",
      "width": 1600,
      "height": 1200,
      "ratio": "4:3"
  },
  {
      "label": "720p",
      "width": 1280,
      "height": 720,
      "ratio": "16:9"
  },
  {
      "label": "SVGA",
      "width": 800,
      "height": 600,
      "ratio": "4:3"
  },
  {
      "label": "480p",
      "width": 640,
      "height": 480,
      "ratio": "4:3"
  },
  {
      "label": "360p",
      "width": 640,
      "height": 360,
      "ratio": "16:9"
  },
  {
      "label": "CIF",
      "width": 352,
      "height": 288,
      "ratio": "4:3"
  },
  {
      "label": "QVGA",
      "width": 320,
      "height": 240,
      "ratio": "4:3"
  },
  {
      "label": "QCIF",
      "width": 176,
      "height": 144,
      "ratio": "4:3"
  },
  {
      "label": "QQVGA",
      "width": 160,
      "height": 120,
      "ratio": "4:3"
  }
];

async function getUserMediaExact(constraints, definition) {
  log.log("getUserMediaExact", definition);
  let r = deviceDefinitions.find((d) => d.label == definition);
  constraints.video = Object.assign(constraints.video, {
    width: {exact: r.width},
    height: {exact: r.height}
  });
  log.log("Try: ", r, constraints);
  return await navigator.mediaDevices.getUserMedia(constraints);
}

async function getUserMediaHighest(constraints) {
  log.log("getUserMediaHighest");
  let index = 0;
  let loop = true;
  let stream = null;
  // Set facing constrain
  if (constraints.video && !constraints.video.deviceId) {
    constraints.video = { facingMode : { exact : "user" }};
    // If fails, sets to legacy writing
    try {
      let stream = await navigator.mediaDevices.getUserMedia(constraints);
      stopStream(stream);
    } catch (e) {
      if (e.constraint == "facingMode")
        constraints.video = { facingMode : "user" };
    }
  }
  while (index < deviceDefinitions.length && loop) {
    try {
      let r = deviceDefinitions[index];
      // https://developer.mozilla.org/en-US/docs/Web/API/Media_Streams_API/Constraints
      constraints.video = Object.assign(constraints.video, {
        width: {exact: r.width},
        height: {exact: r.height}
      });
      log.log("Try: ", index, r, constraints);
      stream = await navigator.mediaDevices.getUserMedia(constraints);
      loop = false;
    } catch (e) {
      if (e.constraint) {
        log.log("Error:", e, e.message, e.constraint);
        index += 1;
      } else 
        throw (e);
    }
  }
  if (loop)
    log.error("could not find a result");
  else
    log.log("getUserMediaHighest success", index, deviceDefinitions[index]);
  return stream;
}

/*
settings {
  video: bool,
  audio: bool,
  definition: low | auto | high | null
}
deviceSettings coming from webrtcSettings.
*/
export async function getUserMedia(settings, deviceSettings) {
  let constrains = {
    video: settings.video, 
    audio: settings.audio,
  };
  if (settings.logOutput)
    log.output = settings.logOutput;
  // Using settings to override
  if (deviceSettings) {
    log.log("deviceSettings=", deviceSettings);
    let s = deviceSettings;
    if (constrains.audio) {
      let ca = constrains.audio || {};
      constrains.audio = Object.assign(ca, {deviceId: s.audioInput ? {exact: s.audioInput} : undefined});
      if (s.audioLatency)
        constrains.audio.latency = s.audioLatency;
    }
    if (constrains.video) {
      let cv = constrains.video || {};
      constrains.video = Object.assign(cv, {deviceId: s.video ? {exact: s.video} : undefined});
      if (deviceSettings.videoDefinition)
        settings.definition = deviceSettings.videoDefinition;
    }
  }
  if (constrains.video) {
    if (settings.definition?.exact) {
      return await getUserMediaExact(constrains, settings.definition.exact);
    } else if (settings.definition == 'low') {
      constrains.video = Object.assign(constrains.video, {
          "width": {
              "min": "320",
              "max": "320"
          },
          "height": {
              "min": "240",
              "max": "240"
          },
          "frameRate": {
              "min": "10",
              "max": "10"
          }
        });
    } else if (settings.definition == 'high') {
      return await getUserMediaHighest(constrains);
    } else if (settings.definition == 'auto') {
      if (getBrowser().startsWith('mobile'))
        return await getUserMediaExact(constrains, '480p');
      else
        return await getUserMediaHighest(constrains);
    }
  }
  log.log("contraints=", constrains);
  return  await navigator.mediaDevices.getUserMedia(constrains);
}

export function getSavedDeviceSettings() {
  let res = null;
  try {
    res = JSON.parse(localStorage.getItem('device-settings'));
  } catch (e) {
    log.log("failed to load from local storage", e);
  }
  return res;
}

export function saveDeviceSettings(s) {
  localStorage.setItem('device-settings', JSON.stringify(s));
}

import workletUrl from 'worklet-loader!@/components/SoundMeter.worklet.js';
import { getBrowser } from "./utils";

export async function createSoundMeter(stream, callback) {
  log.log('createSoundMeter');
  if (!window.audioContext) {
    try {
      window.AudioContext = window.AudioContext || window.webkitAudioContext;
      window.audioContext = new AudioContext();
    } catch (e) {
      log.error('Web Audio API not supported.');
      return;
    }
  }
  let audioContext = window.audioContext;
  let node = null;
  try {
    node = new AudioWorkletNode(audioContext, 'soundmeter-processor');
  } catch (e) {
    await audioContext.audioWorklet.addModule(workletUrl);
    node = new AudioWorkletNode(audioContext, 'soundmeter-processor');
  }
  let mic = audioContext.createMediaStreamSource(stream);
  node.port.onmessage = event => {
    // log.log("node.port.onmessage", event);
    if (event.data)
      callback(event.data);
  }
  mic.connect(node).connect(audioContext.destination)
  return () => {
    mic.disconnect();
    node.disconnect();
  };
}
